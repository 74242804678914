import React from "react";
const Pagination = ({ data, currentPage, lastPage, onPageChange }) => {
    if (!data || lastPage <= 1) {
        return null; // Don't render pagination for a single page or when data is not available
    }
    // Calculate the total number of pages based on the total number of items and items per page
    const totalPages = Math.ceil(data?.total / data?.per_page);
    // Generate the pagination links
    const links = [];
    const maxLinks = 5;
    // Show the first page
    links.push(
        <li
            className={`mt-1 page-item ${currentPage === 1 ? "active" : ""}`}
            key={1}
        >
            <a
                className="page-link"
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    onPageChange(1);
                }}
            >
                1
            </a>
        </li>
    );
    // Show ellipsis if there are more pages
    if (currentPage - Math.floor(maxLinks / 2) > 2) {
        links.push(
            <li className="page-item disabled" key="ellipsis-start">
                <span className="page-link">...</span>
            </li>
        );
    }
    // Show the pages around the current page
    for (
        let i = Math.max(2, currentPage - Math.floor(maxLinks / 2));
        i <= Math.min(totalPages - 1, currentPage + Math.floor(maxLinks / 2));
        i++
    ) {
        const isActive = i === currentPage;
        links.push(
            <li className={`mt-1 page-item ${isActive ? "active" : ""}`} key={i}>
                <a
                    className="page-link"
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        onPageChange(i);
                    }}
                >
                    {i}
                </a>
            </li>
        );
    }
    // Show ellipsis if there are more pages
    if (totalPages - currentPage > Math.floor(maxLinks / 2)) {
        links.push(
            <li className="page-item disabled" key="ellipsis-end">
                <span className="page-link">...</span>
            </li>
        );
    }
    // Show the last page
    links.push(
        <li
            className={`mt-1 page-item ${currentPage === lastPage ? "active" : ""}`}
            key={lastPage}
        >
            <a
                className="page-link"
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    onPageChange(lastPage);
                }}
            >
                {lastPage}
            </a>
        </li>
    );
    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", width: '100%' }}>
            <nav>
                <ul className="pagination" style={{ display: "flex", flexWrap: "wrap", }}>
                    <li className={`mt-1 page-item ${currentPage === 1 ? "disabled" : ""}`}>
                        <a
                            className="page-link"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                onPageChange(currentPage - 1);
                            }}
                            aria-label="Previous"
                        >
                            Previous
                        </a>
                    </li>
                    {links}
                    <li className={`mt-1 page-item ${currentPage === lastPage ? "disabled" : ""}`}>
                        <a
                            className="page-link"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                onPageChange(currentPage + 1);
                            }}
                            aria-label="Next"
                        >
                            Next
                        </a>
                    </li>
                </ul>
            </nav>
            <p>
                Showing {data ? (currentPage - 1) * data.per_page + 1 : 0} to{" "}
                {Math.min(currentPage * (data?.per_page || 0), data?.total || 0)} of {data?.total} entries
            </p>
        </div>
    );
};
export default Pagination;






