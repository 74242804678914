import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { BsPencilSquare, BsTrash, BsPlus } from "react-icons/bs";
import Sidebar from "../Sidebar/sidebar";
import Pagination from "../Pagination";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from "react-loader-spinner";
import { Button, Card, CardBody, CardFooter, CardHeader, Row } from "react-bootstrap";
import "./categories.css";
import { Form } from "react-bootstrap";
import { Footer } from "../Footer/footer";

import Select from 'react-select';


function Categories() {
  const [data, setData] = useState([]);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [name, setName] = useState("");
  const [updateModal, setUpdateModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [addModal, setAddModal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [paginationValue, setPaginationValue] = useState({});
  const token = localStorage.getItem("token");
  const [selectedOption, setSelectedOption] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [categoriesDropdownData, setCategoriesDropdownData] = useState([]);

  const categoriesOptions = [];
  data.map((type) => categoriesOptions.push({ value: type.parent?._id, label: type.parent?.categoryName }));

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  
  const permission = localStorage.getItem("permission");

  const checkPermissions = (permissionName) => {
    return permission === permissionName;
  }

  useEffect(() => {
    listCategories();
    listCategoriesDropdown();
  }, [currentPage, searchValue]);

  const listCategories = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/category/list?pageNo=${currentPage}&searchValue=${searchValue}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
      .then((res) => {
        setLoading(false)
        setData(res.data.result.data);
        setPaginationValue(res.data.result.pagination)
      })
      .catch((err) => toast.error(err.response.data.message)).finally(() => setLoading(false));
  }
  
  const listCategoriesDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/category/list-all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCategoriesDropdownData(res.data.result);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const categoriesDropdownOptions = [];
  categoriesDropdownData.map((type) =>
    categoriesDropdownOptions.push({
      value: type?._id,
      label: type?.categoryName,
    })
  );
  
  const handleUpdate = (id, name) => {
    setSelectedTypeId(id);
    setName(name);
    if (updateModal) updateModal.show();
  };

  const handleDelete = (id) => {
    setSelectedTypeId(id);
    if (deleteModal) deleteModal.show();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const incrementToNextStep = (number) => {
    // Convert number to an integer
    let integerPart = parseInt(number);
    // Add 1 to the integer part
    integerPart++;
    // If there's a decimal part, round it up
    let decimalPart = number - integerPart;
    if (decimalPart > 0) {
        integerPart++;
    }
    return integerPart;
}

  const handleAdd = () => {
    setSelectedTypeId(null);
    setName("");
    if (updateModal) updateModal.hide();
    if (deleteModal) deleteModal.hide();
    if (addModal) addModal.show();
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
    const parentValue = selectedOption ? selectedOption.value : null;
  
    axios
      .post(`${process.env.REACT_APP_SERVER_API}/api/category/update`, {
        categoryName: name,
        id: selectedTypeId,
        parent: parentValue,
      }, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
      .then((res) => {
        const updatedData = data.map((type) => {
          if (type._id === selectedTypeId) {
            return res.data;
          } else if (type.children && type.children.length > 0) {
            const updatedChildren = type.children.map((child) => {
              if (child._id === selectedTypeId) {
                return res.data;
              }
              return child;
            });
            return { ...type, children: updatedChildren };
          }
          return type;
        });
  
        setData(updatedData);
        if (updateModal) updateModal.hide();
        toast.success("Category Updated Successfully");
        setLoading(false);
        listCategories();
        listCategoriesDropdown();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };
  
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  }

  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_SERVER_API}/api/category/delete`, { id: selectedTypeId }, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
      .then(() => {
        const updatedData = data.filter((Type) => Type.id !== selectedTypeId);
        setData(updatedData);
        if (deleteModal) deleteModal.hide();
        toast.success("Category Deleted Successfully")
        listCategories();
        listCategoriesDropdown();
      })
      .catch((err) => toast.error(err.response.data.message)).finally(() => setLoading(false));
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    
    const parentValue = selectedOption ? selectedOption.value : null;
    
    axios
      .post(`${process.env.REACT_APP_SERVER_API}/api/category/add`, {
        categoryName: name,
        parent: parentValue,
      }, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
      .then((res) => {
        setData([...data, res.data]);
        if (addModal) addModal.hide();
        toast.success("Category Added Succesfully")
        setLoading(false)
        listCategories();
        listCategoriesDropdown();

      })
      .catch((err) => toast.error(err.response.data.message)).finally(() => setLoading(false));
  };

  useEffect(() => {
    setUpdateModal(
      new window.bootstrap.Modal(document.getElementById("updateModal"))
    );
    setDeleteModal(
      new window.bootstrap.Modal(document.getElementById("deleteModal"))
    );
    setAddModal(
      new window.bootstrap.Modal(document.getElementById("addModal"))
    );
  }, []);

  const fileInputRefProfile = useRef("");
  const handleUpload = async (e) => {
    const token = localStorage.getItem("token"); setLoading(true)
    const file = e.target.files[0];

    if (!file) {
      toast.error("Please Select a file")
      return;
    }

    // Validate file format (e.g., .xls or .xlsx)
    if (!file.name.match(/\.(xls|xlsx)$/)) {
      toast.error('Please upload an Excel file with .xls or .xlsx format.');
      return;
    }

    try {

      const formData = new FormData();
      formData.append('file', file);


      const response = await axios.post(` ${process.env.REACT_APP_SERVER_API}/api/category/store-bulk`, formData, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      e.target.value = null;
      setLoading(false)
      listCategories();
      toast.success("Uploaded Successfully")

    } catch (error) {
      e.target.value = null;
      console.error('Error uploading file:', error);
      setLoading(false)
      toast.error("Uploading Failed")

    }
  };

  const toggleRowExpansion = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const highlightSearchValue = (value) => {
    if (!searchValue) return value; 
  
    const regex = new RegExp(searchValue, 'gi'); 
    return typeof value === 'string' ? (
      <span dangerouslySetInnerHTML={{
        __html: value.replace(regex, (match) => `<mark style="background-color: yellow; font-weight: bold;">${match}</mark>`)
      }} />
    ) : value;
  };
  
  
  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />
        <Circles
          height="80"
          width="80"
          color="#3155a7"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass="loading-spinner-overlay"
          visible={loading}
        />
        <>
          <div className="col" style={{ height: '100vh', overflow: 'auto' }}>
            <div className="mt-3">
              <h3>Categories Table</h3>
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between" id="heading-line">
                    <input
                      type="text"
                      autoComplete="off"
                      name="search0"
                      id="search0"
                      style={{
                        height: "40px",
                        borderRadius: "5px",
                        border: "1px solid #dbdbdb",
                        padding: "10px",
                      }}
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                    <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                    {checkPermissions("Admin") || checkPermissions("Editor") ? (
                      <button onClick={handleAdd} style={{ backgroundColor: "#3155a7", color: "#fff" }} className="btn heading-line-button mx-1">
                        <BsPlus /> Add Category
                      </button>
                    ):null}
                      <Form.Control
                        name="profilePicture"
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRefProfile}
                        onChange={handleUpload}
                      />
                      {checkPermissions("Admin") || checkPermissions("Editor") ? (
                      <button onClick={() => fileInputRefProfile.current?.click()} style={{ backgroundColor: "#3155a7", color: "#fff" }} className="heading-line-button btn mx-1">
                        Upload Data  <BsPlus />
                      </button>
                      ):null}
                      <a className='heading-line-button btn btn-primary mx-1' href={`${process.env.REACT_APP_SERVER_API}/${process.env.REACT_APP_DOWNLOAD_CATEGORY_PATH}`} target="_blank">Download sample</a>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-box" style={{ overflowX: "auto",height:'65vh' }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Created_At</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                     {data.length > 0 ? (
                        data.map((category) => (
                          <React.Fragment key={category?.parent?._id}>
                            <tr>
                              <td style={{paddingTop:'12px'}}>
                                {highlightSearchValue(category?.parent?.categoryName)} 
                                {expandedRow === category?.parent?._id && (
                                  <React.Fragment>
                                    {category?.children?.length > 0 ? (
                                      category.children.map((child) => (
                                        <tr key={child._id}>
                                          <td style={{paddingTop:'19px',textDecoration: 'underline',color: 'chocolate'}}>
                                            {highlightSearchValue(child?.categoryName)}
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="12"></td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                )}
                              </td>
                              <td style={{paddingTop:'12px'}}>{highlightSearchValue(category.parent?.createdAt)} 
                                {expandedRow === category?.parent?._id && (
                                  <React.Fragment>
                                    {category?.children?.length > 0 ? (
                                      category.children.map((child) => (
                                        <tr key={child._id}>
                                          <td style={{paddingTop:'19px',textDecoration: 'underline',color: 'chocolate'}}>
                                            {highlightSearchValue(child?.createdAt)}
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr style={{    display:' grid',justifyItems: 'center',}}>
                                        <td style={{paddingTop:'19px', fontWeight:'bold'}}>No data found</td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                )}
                              </td>
                              
                              <td  colSpan="3">
                                <Button size="sm" className="btn btn-sm btn-success mx-1 my-1" onClick={() => toggleRowExpansion(category?.parent?._id)}>Expand</Button>
                                {checkPermissions("Admin") || checkPermissions("Editor") ? (
                                <button
                                  onClick={() => handleUpdate(category.parent?._id, category?.parent?.categoryName, category?.parent?.createdAt)}
                                  className="btn btn-sm btn-primary my-1 mx-1"
                                >
                                  <BsPencilSquare />
                                </button>
                                ):null}
                                 {checkPermissions("Admin") || checkPermissions("Editor") ? (
                                <button
                                  onClick={() => handleDelete(category.parent?._id)}
                                  className="btn btn-sm btn-danger my-1 mx-1"
                                >
                                  <BsTrash />
                                </button>
                                 ):null}
                                {(expandedRow === category.parent?._id && category.children.length>0) && (
                                  category.children.map((child)=>(                                
                                  <tr key={child._id}>
                                     {checkPermissions("Admin") || checkPermissions("Editor") ? (
                                    <td>
                                      <button
                                        onClick={() => handleUpdate(child?._id, child?.categoryName,child?.createdAt)}
                                        className="btn btn-sm btn-primary my-1 mx-1"
                                      >
                                        <BsPencilSquare />
                                      </button>
                                      <button
                                        onClick={() => handleDelete(child?._id)}
                                        className="btn btn-sm btn-danger my-1 mx-1"
                                      >
                                        <BsTrash />
                                      </button>
                                    </td>
                                     ):null}
                                  </tr>
                                  ))
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                          ))
                        ) : (
                          <tr>
                            <th colSpan={6} className="text-center">No Data Found</th>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                </CardBody>
                <CardFooter>
                  <div style={{ display: "flex", alignItems: "center" }}>
                  <Pagination
                      currentPage={currentPage}
                      data={{'per_page':pageSize, 'total': paginationValue.total}}
                      lastPage={incrementToNextStep(paginationValue.total/pageSize)}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </CardFooter>
              </Card>
            </div>

            <div className="modal" id="updateModal" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleUpdateSubmit}>
                    <div className="modal-header">
                      <h5 className="modal-title">Update Category</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        <label htmlFor="name" className="form-label mt-2">
                          Parent
                        </label>
                        <Select
                          options={categoriesDropdownOptions}
                          name="typeFilter"
                          value={selectedOption}
                          onChange={handleChange}
                          placeholder="Categories"
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="modal" id="deleteModal" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleDeleteSubmit}>
                    <div className="modal-header">
                      <h5 className="modal-title">Delete Category</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>Are you sure you want to delete this Category?</p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-danger">
                        Delete
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="modal" id="addModal" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleAddSubmit}>
                    <div className="modal-header">
                      <h5 className="modal-title">Add Category</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        <label htmlFor="name" className="form-label mt-2">
                          Parent
                        </label>
                        <Select
                          options={categoriesDropdownOptions}
                          name="typeFilter"
                          value={selectedOption}
                          onChange={handleChange}
                          placeholder="Categories"
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Add Category
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </>
      </div>
    </div>
  );
}

export default Categories;
