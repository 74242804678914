import React, { useEffect } from "react";
import Sidebar from "../Sidebar/sidebar";
import Users from "../Users/users";
import { useNavigate } from 'react-router-dom';
import "./dashboard.css"
import {Footer} from "../Footer/footer";
// import Types from "../Types/types";

function Dashboard() {
  const navigate = useNavigate()
  const permission = localStorage.getItem("permission");
  
  console.log({permission})
   useEffect(() => {
     if (permission !== "Admin") {
       navigate("/websites")
     }
   }, []);

  return (
    permission === "Admin" ?
      <div className="container-fluid" >
        <div className="row">
          <Sidebar />
          <>
          <div className="col" style={{height:'100vh', overflow:'auto'}}>
            <Users />
            <Footer />
          </div>
          </>
        </div>
      </div>
      
       :
       <div>
         <h1>Restricted</h1>
       </div>
  );
}

export default Dashboard;
