import React, { useState } from 'react'
import logo from "../../img/logo.jpeg"
import { MdEmail } from "react-icons/md"
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from "react-loader-spinner";
import { Link } from 'react-router-dom';

const Forgot = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        axios
            .post(`${process.env.REACT_APP_SERVER_API}/api/auth/forgot-password`, {
                email
            })
            .then((res) => {
                toast.success(res.data.message)

            })
            .catch((err) => toast.error(err.response.data.message)).finally(() => setLoading(false));
    }





    return (
        <>

            <Circles
                height="80"
                width="80"
                color="#3155a7"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="loading-spinner-overlay"
                visible={loading}
            />
            <div style={{ height: "100lvh",backgroundImage:'linear-gradient(#827cf4,#090979 ,#00d4ff)' }} className='d-flex justify-content-center align-items-center '>
                <div className="wrapper">
                    <div className="logo">
                        <img src={logo} alt="logo" />
                    </div>
                    <form className="mt-5" onSubmit={handleSubmit}>
                        <h1>Forgot Password</h1>
                        <p style={{ fontWeight: "500" }} >Please Enter Your Registered Email. You will receive recovery email shortly</p>
                        <div className="input-box">
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <MdEmail className="icon" />
                        </div>

                        <button >Submit</button>
                    </form>

                    <Link className='m-auto d-flex justify-content-center mt-2' to="/" >Back to Login</Link>
                </div>
            </div>
        </>)
}

export default Forgot;