import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineLogout } from "react-icons/md";
import "./logout.css";
const Logout = () => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");

  const handleSignOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    toast.success("Logout successfully");
    navigate("/");
  };

  return (
    <div>
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
      <button
        className="btn btn-outline-light"
        id="logout-btn"
        onClick={handleSignOut}
        style={{ fontSize: "1rem", fontWeight: "bold" }}
      >
          Log Out
      </button>

      <div className="icon-logout">
        <i className="bi bi-box-arrow-right text-white"></i>
      </div>
    </div>
  );
};

export default Logout;
