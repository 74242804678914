import React, { useEffect, useState } from "react";
import axios from "axios";
import "./users.css";
import { BsPencilSquare, BsTrash, BsPlus } from "react-icons/bs";
import Pagination from "../Pagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Circles } from "react-loader-spinner";
import { Card, CardBody, CardFooter, CardHeader } from "react-bootstrap";

function Users() {
  const [data, setData] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [updateModal, setUpdateModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [paginationValue, setPaginationValue] = useState({});
  const [selectedDepOptions, setSelectedDepOptions] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedReportData, setSelectedReportData] = useState([]);
  const [depData, setDepData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [permission, setPermission] = useState('Viewer'); 

  
  const userOptions = [];
  data.map((users) =>
    userOptions.push({ value: users._id, label: users.name })
  );

  const userListOptions = [];
  userListData.map((users) =>
    userListOptions.push({ value: users._id, label: users.name })
  );

  useEffect(() => {
    listDepartments();
    getUserList();
    getUserListAll();
  }, [currentPage, searchValue]);

  useEffect(() => {
    setUpdateModal(
      new window.bootstrap.Modal(document.getElementById("updateModal"))
    );
    setDeleteModal(
      new window.bootstrap.Modal(document.getElementById("deleteModal"))
    );
    setAddModal(
      new window.bootstrap.Modal(document.getElementById("addModal"))
    );
  }, []);

  const token = localStorage.getItem("token");
  const handleUpdate = (id, name, email, department,reportTo) => {
    console.log(reportTo,"here");
    setSelectedUserId(id);
    setName(name);
    setEmail(email);
    setSelectedData({ value: department._id, label: department.name });
    setSelectedReportData({value:reportTo?._id, label: reportTo?.name})

    if (updateModal) updateModal.show();
  };

  const handleDelete = (id) => {
    setSelectedUserId(id);
    if (deleteModal) deleteModal.show();
  };
  
  const incrementToNextStep = (number) => {
    // Convert number to an integer
    let integerPart = parseInt(number);
    // Add 1 to the integer part
    integerPart++;
    // If there's a decimal part, round it up
    let decimalPart = number - integerPart;
    if (decimalPart > 0) {
        integerPart++;
    }
    return integerPart;
}


  const handleAdd = () => {
    setSelectedUserId(null);
    setName("");
    setEmail("");
    setPassword("");
    if (updateModal) updateModal.hide();
    if (deleteModal) deleteModal.hide();
    if (addModal) addModal.show();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getUserList = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/api/user/list?pageNo=${currentPage}&searchValue=${searchValue}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.result.data);
        setPaginationValue(res.data.result.pagination);
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  const getUserListAll = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/user/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUserListData(res.data.result);
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_API}/api/user/update`,
        {
          name,
          id: selectedUserId,
          department: selectedDepOptions.value,
          reportTo: selectedOption?.value,
          permission,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const updatedData = data.map((user) =>
          user.id === selectedUserId ? res.data : user
        );
        setData(updatedData);
        if (updateModal) updateModal.hide();
        toast.success("User Updated Successfully");
        setLoading(false);
        getUserList();
        getUserListAll();
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  const listDepartments = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/api/department/list?pageNo=${currentPage}&search=${searchValue}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setDepData(res.data.result.data);
        setPaginationValue(res.data.result.pagination);
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };
  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_API}/api/user/delete`,
        {
          id: selectedUserId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(
        () => {
          const updatedData = data.filter(
            (user) => user._id !== selectedUserId
          );
          setData(updatedData);
          if (deleteModal) deleteModal.hide();
          toast.success("User Deleted Successfully");
          getUserList();
          getUserListAll();
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const depOptions = [];
  console.log({ depData });
  depData.map((type) => depOptions.push({ value: type._id, label: type.name }));

  const handleAddSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_API}/api/user/add`,
        {
          name,
          email,
          department: selectedDepOptions.value,
          reportTo: selectedOption?.value,
          permission,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData([...data, res.data]);
        if (addModal) addModal.hide();
        toast.success("User Added Successfully");
        setLoading(false);
        getUserList();
        getUserListAll();
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };
  

  const handleDepChange = (selectedDepOptions) => {
    console.log({ selectedDepOptions });
    setSelectedDepOptions(selectedDepOptions);
    setSelectedData(selectedDepOptions);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedReportData(selectedOption);
  };
  
  const highlightSearchValue = (value) => {
    if (!searchValue) return value;

    const regex = new RegExp(searchValue, "gi");
    return typeof value === "string" ? (
      <span
        dangerouslySetInnerHTML={{
          __html: value.replace(
            regex,
            (match) =>
              `<mark style="background-color: yellow; font-weight: bold;">${match}</mark>`
          ),
        }}
      />
    ) : (
      value
    );
  };

  return (
    <div className="container-fluid p-0">
      <Circles
        height="80"
        width="80"
        color="#3155a7"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="loading-spinner-overlay"
        visible={loading}
      />
      <div className="mt-3">
        <h3>Users Table</h3>
        <Card id="card-box">
          <CardHeader>
            <div className="d-flex justify-content-between" id="card-header">
              <input
                type="text"
                autoComplete="off"
                name="search0"
                id="search0"
                style={{
                  height: "40px",
                  borderRadius: "5px",
                  border: "1px solid #dbdbdb",
                  padding: "10px",
                }}
                placeholder="Search"
                onChange={handleSearchChange}
              />

              <button
                onClick={handleAdd}
                style={{ backgroundColor: "#3155a7", color: "#fff" }}
                className="btn"
              >
                <BsPlus /> Add User
              </button>
            </div>
          </CardHeader>
          <CardBody>
            <div className="table-box" style={{ overflowX: "auto", height:'65vh' }}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Reports To</th>
                    <th scope="col">Department</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((user) => (
                      <tr key={user._id}>
                        <td>{highlightSearchValue(user.name)}</td>
                        <td>{highlightSearchValue(user.email)}</td>
                        <td>{highlightSearchValue(user.reportTo?.name)}</td>
                        <td>{highlightSearchValue(user.department?.name)}</td>
                        <td>
                          <button
                            onClick={() =>
                              handleUpdate(
                                user._id,
                                user.name,
                                user.email,
                                user.department,
                                user.reportTo
                              )
                            }
                            className="btn btn-sm btn-primary my-1 me-2"
                          >
                            <BsPencilSquare />
                          </button>
                          <button
                            onClick={() => handleDelete(user._id)}
                            className="btn btn-sm btn-danger my-1"
                          >
                            <BsTrash />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <th colSpan={5} className="text-center">
                        No Data Found
                      </th>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
          <CardFooter>
          <div
          style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Pagination
              currentPage={currentPage}
              data={{'per_page':pageSize, 'total': paginationValue.total}}
              lastPage={incrementToNextStep(paginationValue.total/pageSize)}
              onPageChange={handlePageChange}
            />
          </div>
          </CardFooter>
        </Card>
      </div>

      <div className="modal" id="updateModal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={handleUpdateSubmit}>
              <div className="modal-header">
                <h5 className="modal-title">Update User</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="my-2">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="my-2">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    disabled
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="my-2">
                  <label htmlFor="Types" className="form-label">
                    Department *
                  </label>

                  <Select
                    required
                    options={depOptions}
                    value={selectedData}
                    onChange={handleDepChange}
                  />
                </div>
                <div className="my-2">
                  <label htmlFor="Types" className="form-label">
                    Reports To
                  </label>
                  <Select
                    options={userListOptions}
                    value={selectedReportData}
                    onChange={handleChange}
                  />
                </div>
                <div className="my-2">
                    <label htmlFor="permission" className="form-label">
                      Permission
                    </label>
                    <select value={permission} onChange={(e) => setPermission(e.target.value)} required className="form-control">
                      <option value="Admin">Admin</option>
                      <option value="Editor">Editor</option>
                      <option value="Viewer">Viewer</option>
                    </select>

                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="modal" id="deleteModal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={handleDeleteSubmit}>
              <div className="modal-header">
                <h5 className="modal-title">Delete User</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this user?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-danger">
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="modal" id="addModal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={handleAddSubmit}>
              <div className="modal-header">
                <h5 className="modal-title">Add User</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="my-2">
                  <label htmlFor="name" className="form-label">
                    Name *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="my-2">
                  <label htmlFor="email" className="form-label">
                    Email *
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <div className="my-2">
                    <label htmlFor="Types" className="form-label">
                      Department *
                    </label>
                    <Select
                      required
                      options={depOptions}
                      value={selectedDepOptions}
                      onChange={handleDepChange}
                    />
                  </div>
                  <div className="my-2">
                    <label htmlFor="Types" className="form-label">
                      Reports To
                    </label>
                    <Select
                      options={userListOptions}
                      value={selectedOption}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="my-2">
                    <label htmlFor="permission" className="form-label">
                      Permission
                    </label>
                    <select value={permission} onChange={(e) => setPermission(e.target.value)} required className="form-control">
                      <option value="Admin">Admin</option>
                      <option value="Editor">Editor</option>
                      <option value="Viewer">Viewer</option>
                    </select>

                  </div>
                  {/* <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password *
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div> */}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Add User
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
