import React, { useEffect, useState } from "react";
import "./loginStyles.css";
import { FaLock } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../../img/logo.jpeg"

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  
  useEffect(() => {
    if (authToken) {
      window.location.href = "/dashboard"
    }
  }, [])


  const logIn = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      setLoading(true);
      let items = { email, password };
      let result = await fetch(`${process.env.REACT_APP_SERVER_API}/api/auth/login`, {
        method: "POST",
        body: JSON.stringify(items),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (!result.ok) {
        setLoading(false)
        toast.error("Email/Password wrong")
        throw new Error("Login failed");

      }
      result = await result.json();
      console.log(result)
      localStorage.setItem("user-info", JSON.stringify(result));
      localStorage.setItem("token", result.token)
      localStorage.setItem("role", result.role)
      localStorage.setItem("permission", result.result.permission)
      localStorage.setItem("userId", result?.result?._id)
      toast.success("Welcome Back")
      if (role === "Admin") { navigate("/dashboard") } else {
        navigate("/websites")
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    !authToken ?
      <div className="container1 ">
        < Circles
          height="80"
          width="80"
          color="#3155a7"
          ariaLabel="bars-loading"
          wrapperStyle={{}
          }
          wrapperClass="loading-spinner-overlay"
          visible={loading}
        />
        <div className="wrapper">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <form className="mt-5" onSubmit={logIn}>
            <h1>Login</h1>
            <div className="input-box">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <MdEmail className="icon" />
            </div>
            <div className="input-box">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <FaLock className="icon" />
            </div>
            <Link to="/forgot" className="cursor-pointer mb-1 w-100 d-flex justify-content-end font-weight-bold text-decoration-underline">
              Forget Password?
            </Link>
            <button type="submit">Login</button>
          </form>
        </div>
      </div > 
      : <div className="container1 "></div>
  );
};

export default LoginForm;
