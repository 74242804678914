import React, { useState } from 'react'
import logo from "../../img/logo.jpeg"
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from "react-loader-spinner";
import { useParams } from 'react-router-dom';
import { FaLock } from 'react-icons/fa';
import Sidebar from '../Sidebar/sidebar';
import {Card, CardBody, CardHeader} from 'react-bootstrap';
import {Footer} from '../Footer/footer';

const ChangePassword = () => {
    const [loading, setLoading] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const navigate = useNavigate();

    let userInfoString = localStorage.getItem("user-info");
    let userInfo = JSON.parse(userInfoString);
    const userId = userInfo.result._id;
    
    console.log("User ID:", userId); 
    
    const token = localStorage.getItem("token")
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        axios
            .post(`${process.env.REACT_APP_SERVER_API}/api/auth/change-password`, {
                userId, oldPassword, newPassword, confirmNewPassword
            }, {
                headers: {
                  "Authorization": `Bearer ${token}`
                }})
                
            .then((res) => {
                toast.success(res.data.message)
                navigate("/")

            })
            .catch((err) => toast.error(err.response.data.message)).finally(() => setLoading(false));
    }

    return (
        <>
            <Circles
                height="80"
                width="80"
                color="#3155a7"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="loading-spinner-overlay"
                visible={loading}
            />
            <div className='row m-0'>
                <Sidebar />
                <>
                <div className='col' style={{ height: "100vh",overflow:'auto'}}>
                    <div className="mt-3" style={{marginBottom:'70px'}}>
                        <h3>Change Password</h3>
                        <Card>
                            <CardHeader>
                            </CardHeader>
                            <CardBody>
                            <div className='d-flex justify-content-center align-items-center '>
                                    <div className="changePasswordWrapper">
                                        <form onSubmit={handleSubmit}>
                                            <div className="input-box-change">
                                                <label htmlFor="newPassword">Old Password </label>
                                                <input
                                                    type="password"
                                                    placeholder="Old Password"
                                                    id="oldPassword"
                                                    value={oldPassword}
                                                    onChange={(e) => setOldPassword(e.target.value)}
                                                    required
                                                />
                                                <FaLock className="icon" />
                                            </div>
                                            <div className="input-box-change">
                                                <label htmlFor="newPassword">New Password </label>
                                                <input
                                                    type="password"
                                                    placeholder="New Password"
                                                    id="newPassword"
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    required
                                                />
                                                <FaLock className="icon" />
                                            </div>

                                            <div className="input-box-change">
                                                <label htmlFor="confirmPassword">Confirm Password </label>
                                                <input
                                                    type="password"
                                                    placeholder="Confirm New Password"
                                                    id="confirmPassword"
                                                    value={confirmNewPassword}
                                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                                    required
                                                />
                                                <FaLock className="icon" />
                                            </div>

                                            <button >Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Footer />
                    </div>    
                </div>
                </>
            </div>
        </>)
}

export default ChangePassword;