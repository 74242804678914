import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/collapse";
import "./footerStyle.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

export function Footer() {
  return (
    <>
      <div className="footer adjustFooter" id="footerTooltip" aria-current="page">
        Powered By Qwerty Experts.
      </div>
      <ReactTooltip anchorSelect="#footerTooltip" place="top-end" effect="solid">
        Powered By QWERTY ENGINEERING
      </ReactTooltip>
    </>
  );
}
